import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo, LocationUserPojo } from 'src/app/pojo/management-user-pojo';
import { MRolePojo } from 'src/app/pojo/role-master.pojo';
import { LocationPojo } from 'src/app/pojo/location.pojo';
import { RoleCreationService } from '../../Services/role-creation.service';
import { LocationMasterService } from 'src/app/Direct-Booking/Services/location-master.service';
import { UserCreationService } from '../../Services/user-creation.service';
import { BookingService } from 'src/app/Online-Booking/Services/booking.service';



declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-user-creation',
    templateUrl: './user-creation.component.html',
    styleUrls: ['./user-creation.component.scss']
})
export class UserCreationComponent implements OnInit {

    loggedUser = new MUserMasterPojo();
    userCreationArr: MUserMasterPojo[] = [];
    userCreationSingle = new MUserMasterPojo();
    roles: MRolePojo[];
    editUser = new MUserMasterPojo();
    addUser = new MUserMasterPojo();
    locationArr: LocationPojo[];
    tempArr: LocationUserPojo[];
    editloc = new LocationPojo();

    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    templeId:number;

    isLoading: boolean = true;
    Bookingservice:any;




    constructor(private bookingService:BookingService,private roleCreationService: RoleCreationService, private locationMasterService: LocationMasterService, private userCreationService: UserCreationService) { }

    templeList: any[] =[];
    ngOnInit() {


        this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));

        this.getRoleAll();
        this.getManagementUserAll();
        this.getlocationtMasterAll();
        this.getTempleList();


        $('#addAfterClick').hide();
        $('#editAfterClick').hide();
        $('#deleteAfterClick').hide();
        $('#checkSpinner').hide();



    }

    getTempleList(){
        this.bookingService.getTempleListByTempleId(this.loggedUser.templeId).subscribe((res)=>{
            this.templeList = res;

        },err=>{
            bootbox.alert({ message: 'error'+err, className: 'text-danger h5 text-center', closeButton: false });

        });
    }

    getlocationtMasterAll() {
        this.locationMasterService.getLocationAll().subscribe(response => {
            this.locationArr = response;
        }, error => {
            bootbox.alert('Get Location All Error! - HTTP Status ' + error.status);
        });
    }

    getLocationById(id: number) {
        if (this.locationArr.find(r => r.locationId == id)) {
            return this.locationArr.find(r => r.locationId == id).location;
        } else {
            return id;
        }
    }

    getRoleAll() {
        this.roleCreationService.getMRoleAll().subscribe(response => {
            this.roles = response;
        }, error => {
            bootbox.alert('Get M Role All Service Error! - HTTP Status ' + error.status);
        });
    }

    getManagementUserAll() {
        this.userCreationService.getManagementUser().subscribe(response => {
            this.userCreationArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert('Get M User Master All Service Error! - HTTP Status ' + error.status);
            this.isLoading = false;
        });
    }

    getRoleKeyByRoleId(id: number) {
        if (this.roles.find(r => r.roleId == id)) {
            return this.roles.find(r => r.roleId == id).roleKey;
        } else {
            return id;
        }
    }

    checkUserName(userName: string) {
        if (userName != "") {
            this.userCreationService.checkUserName(userName).subscribe(response => {
                if (response) {
                } else {
                    alert("Username Already exist..");
                }
            }, error => {
                alert('Check User Name Service Error! - HTTP Status ' + error.status);
            });
        }
    }

    phoneNumValidationForAdd(num: string) {
        const contactNum = /^[6-9]\d{9}$/;
        if (num.match(contactNum) || num == '') {
        } else {
            (<HTMLInputElement>document.getElementById('addMobile')).value = '';
            alert('Please check the mobile number. This number is not valid.');
        }
    }

    phoneNumValidationForEdit(num: string) {
        const contactNum = /^[6-9]\d{9}$/;
        if (num.match(contactNum) || num == '') {
        } else {
            (<HTMLInputElement>document.getElementById('editMobile')).value = '';
            alert('Please check the mobile number. This number is not valid.');
        }
    }

    insertUser() {
        $('#addBeforeClick').hide(); $('#addAfterClick').show();

        this.addUser.locationUserPojo = [];
        for (let i = 0; i < this.locationArr.length; i++) {
            let locTicketMaster = new LocationUserPojo();
            if ((<HTMLInputElement>document.getElementById('loc' + i)).checked == true) {
                locTicketMaster.status = true;
                locTicketMaster.locationId = this.locationArr[i].locationId;
                locTicketMaster.userId = this.loggedUser.userId;
                this.addUser.locationUserPojo.push(locTicketMaster);
            }
            if (i == this.locationArr.length - 1) {
                this.addUser.roleId = +(<HTMLFormElement>document.getElementById('roleId')).value;
                this.addUser.roleKey.roleKey = String(this.getRoleKeyByRoleId(this.addUser.roleId));
                this.addUser.userId = this.loggedUser.userId;
                this.addUser.companyId = 1;
                this.userCreationService.addManagementUser(this.addUser).subscribe(response => {
                    bootbox.alert('User has been created successfully.');
                    (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
                    (<HTMLFormElement>document.getElementById('addForm')).reset();
                    $('#addBeforeClick').show(); $('#addAfterClick').hide();
                    $('#addModal').modal('hide');
                    this.getManagementUserAll();
                }, error => {
                    bootbox.alert('Add M User Master Service Error! - HTTP Status ' + error.status);
                    (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
                    $('#addBeforeClick').show(); $('#addAfterClick').hide();
                });
            }
        }
    }

    clickEdit(arg: MUserMasterPojo) {
        this.id = arg.userId;
        this.editUser = arg;
        for (let a = 0; a < this.locationArr.length; a++) {
            (<HTMLInputElement>document.getElementById('editloc' + a)).checked = false;
            for (let b = 0; b < this.editUser.locationUserPojo.length; b++) {
                if (this.locationArr[a].locationId == this.editUser.locationUserPojo[b].locationId) {
                    (<HTMLInputElement>document.getElementById('editloc' + a)).checked = true;
                }
            }
        }
    }

    updateUser() {
        (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
        $('#editBeforeClick').hide(); $('#editAfterClick').show();
        this.editUser.locationUserPojo = [];
        for (let i = 0; i < this.locationArr.length; i++) {
            let locTicketMaster = new LocationUserPojo();
            if ((<HTMLInputElement>document.getElementById('editloc' + i)).checked == true) {
                locTicketMaster.status = true;
                locTicketMaster.locationId = this.locationArr[i].locationId;
                locTicketMaster.userId = this.editUser.userId;
                this.editUser.locationUserPojo.push(locTicketMaster);
            }
            if (i == this.locationArr.length - 1) {
                const i = this.userCreationArr.indexOf(this.userCreationArr.find(r => r.userId == this.id));
                this.editUser.userId = this.editUser.userId;
                this.editUser.roleId = (<HTMLFormElement>document.getElementById('editroleId')).value;
                this.userCreationService.updateManagementUser(this.editUser).subscribe(() => {
                    bootbox.alert('User has been updated.');
                    (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                    $('#editBeforeClick').show(); $('#editAfterClick').hide();
                    $('#editModal').modal('hide');
                    this.getManagementUserAll();
                    this.getlocationtMasterAll();
                }, error => {
                    bootbox.alert('Update M User Master Service Error! - HTTP Status ' + error.status);
                    (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
                    $('#editBeforeClick').show(); $('#editAfterClick').hide();
                });
            }
        }
    }

    delete(arg: number) {
        this.id = arg;
    }

    deleteConfirm() {
        $('#deleteBeforeClick').hide(); $('#deleteAfterClick').show();
        this.userCreationService.deleteManagementUser(this.id, 1).subscribe(response => {
            let no = response;
            if (no == 0) {
                bootbox.alert('This row already deleted!');
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            } else {
                bootbox.alert('Deleted');
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            }
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            $('#deleteModal').modal('hide');
            this.getManagementUserAll();
        }, error => {
            bootbox.alert('Delete M User Master Service Error! - HTTP Status ' + error.status);
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
        });
    }

}
